import React, { useState } from 'react';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const VerifyCode = () => {
  const { state } = useLocation();
  const { mobileNumber } = state;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [verificationCode, setVerificationCode] = useState('');
  const navigate = useNavigate();

  const handleVerify = async () => {
    try {
      setIsSubmitting(true);

      const response = await axios.post(`${process.env.REACT_APP_URL}/user/verify-code`, {
        mobileNumber,
        verificationCode,
      });

      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 15000 });
        // اگر درخواست موفقیت‌آمیز بود، ریدایرکت به صفحه VerifyCode
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('mobileUser', mobileNumber);

        // Redirect to the dashboard
        navigate(`/my-orders`);

      } else {
        // در غیر این صورت نمایش پیام خطا
        console.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 15000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className='py-5'>
        <div className="form-order br-10">
          <div className='mb-2 fw-700'>کد تایید</div>
          <input
            type="text"
            name="verificationCode"
            value={verificationCode}
            onChange={(e) => {
              // Use regex to allow only numeric characters
              const numericValue = e.target.value.replace(/\D/g, '');

              // Limit the input to 4 characters
              const limitedValue = numericValue.slice(0, 4);

              // Update the state
              setVerificationCode(limitedValue);
            }}
            className="input-form-dashboard-step mb-20"
            placeholder='کد تایید'
          />

          <div className='flex justify-end items-center'>
            <button type="button" className="btn btn-primary p-10 fw-700 br-5" onClick={handleVerify} disabled={isSubmitting}>
              {isSubmitting ? 'لطفا صبر کنید' : 'ثبت'}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCode;
