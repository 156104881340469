import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section className="w-full flex justify-center">
      <div className="flex flex-col p-10">
        <div className="flex flex-col items-center w-full">
          <div>
            <p className="mt-10 fw-700 fs-12 text-center">از طریق فرم زیر می‌توانید سفارشات خود را انجام دهید</p>
            <p className="mt-10 fs-12 text-center">
              قبل از ثبت خرید،{" "}
              <Link to="/policy" style={{ color: "#c500f4" }}>
                قوانین ثبت سفارش
              </Link>{" "}
              را مطالعه فرمایید
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
