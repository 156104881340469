import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(`${process.env.REACT_APP_URL}/user/login`, {
        mobileNumber,
      });

      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 15000 });
        navigate(`/verify`, { state: { mobileNumber } });
      } else {
        toast.error(response.data.message, { autoClose: 15000 });
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 15000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className='py-5'>
        <div className="form-order br-10">
          <div className='mb-2 fw-700'>شماره موبایل</div>
          <input
            type="tel"
            name="mobileNumber"
            value={mobileNumber}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, '');
              const limitedValue = numericValue.slice(0, 11);
              setMobileNumber(limitedValue);
            }}
            className="input-form-dashboard-step mb-20 text-right"
            placeholder='شماره موبایل'
          />

          <div className='flex justify-end items-center'>
            <button type="button" className="btn btn-primary p-10 fw-700 br-5" onClick={handleLogin} disabled={isSubmitting}>
              {isSubmitting ? 'لطفا صبر کنید' : 'ارسال کد'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
