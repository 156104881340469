export default function Contact() {

    return (
        <>
            <div className='payment-body max-width-1200 p-24 mt-20 mb-20'>
                <div className='flex flex-col '>
                    <h2 className="fw-700">پشتیبانی تلگرام - واتس اپ : 09366363644</h2>
                </div>

            </div>

        </>
    )
}
