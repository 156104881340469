
export const FooterNavs  = [
    {
      id: 1,
      category: "خدمات",
      navs: [
        {
          id: 1,
          value: "خرید لایک اینتاگرام",
          href: "/",
        },
        {
          id: 2,
          value: "خرید ویو اینستاگرام",
          href: "/",
        },
        {
          id: 3,
          value: "خرید فالوور اینستاگرام",
          href: "/",
        },
        {
          id: 4,
          value: "خرید ریلز اینستاگرام",
          href: "/",
        },
      ],
    },
    {
      id: 2,
      category: "پل های ارتباطی",
      navs: [
        {
          id: 1,
          value: "واتس اپ",
          href: "/",
        },
        {
          id: 2,
          value: "تلگرام",
          href: "/",
        },
        {
          id: 3,
          value: "اینستاگرام",
          href: "/",
        },
      ],
    },
  ];