import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loading from '../../utils/Loading';

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  return (
    <div className="pagination-container">
      {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
        <button
          key={page}
          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      ))}
    </div>
  );
};

const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
  });

  const fetchData = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_URL}/order/my-order?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const { orders, pagination } = response.data;

      setOrders(orders);
      setPagination(pagination);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data for the initial rendering
  }, []);

  const handlePageChange = (newPage) => {
    fetchData(newPage);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='container mx-auto p-4'>
          <table>
            <caption>لیست سفارشات شما</caption>
            <thead>
              <tr>
                <th scope="col">سرویس</th>
                <th scope="col">تعداد</th>
                <th scope="col">قیمت</th>
                <th scope="col">تاریخ</th>
                <th scope="col">لینک پست</th>
              </tr>
            </thead>
            <tbody>
              {orders.map(order => (
                <tr key={order._id}>
                  <td data-label="سرویس">{order.service}</td>
                  <td data-label="تعداد">{order.quantity}</td>
                  <td data-label="قیمت">{order.priceOrder}</td>
                  <td data-label="تاریخ">{order.createdAt}</td>
                  <td data-label="لینک پست">
                    <Link to={order.link}>
                      مشاهده پست
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default MyOrder;
