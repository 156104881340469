import { BrowserRouter, Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserMain from './pages/UserDashboard/UserMain';


const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={8000} />
      <Routes>
        <Route path="/*" element={<UserMain />} />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
