import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Header = () => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    localStorage.clear();
    navigate('/');
  };

  const hasToken = localStorage.getItem('token');

  const navItems = hasToken
    ? [
        { id: 1, text: 'صفحه اصلی', href: '/' },
        { id: 3, text: 'سفارشات من', href: '/my-orders' },
        { id: 4, text: 'تماس با ما', href: '/contact' },
        { id: 5, text: 'خروج' },
      ]
    : [
        { id: 1, text: 'صفحه اصلی', href: '/' },
        { id: 2, text: 'ورود به حساب کاربری', href: '/login' },
        { id: 4, text: 'تماس با ما', href: '/contact' },
      ];

  useEffect(() => {
    if (!hasToken && location.pathname === '/dashboard') {
      navigate('/login');
    }
  }, [hasToken, location.pathname]);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='bg-black flex justify-between items-center max-w-screen-3xl mx-auto p-4 text-white'>
      <h1 className='w-full font-bold'>آسمان ممبر</h1>

      {/* Desktop Navigation */}
      <ul className='hidden md:flex'>
        {navItems.map((item) => (
          <li
            key={item.id}
            className='max-content p-2 hover:bg-[#00df9a] rounded-xl cursor-pointer duration-300 hover:text-black'
          >
            {item.id === 5 ? (
              <span onClick={logout}>{item.text}</span>
            ) : (
              <Link to={item.href}>{item.text}</Link>
            )}
          </li>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className='block md:hidden z-index-2 pointer'>
        {nav ? 'بستن' : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500'
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
        }
      >
        <h1 className='w-full font-bold m-4'>آسمان ممبر</h1>

        {/* Mobile Navigation Items */}
        {navItems.map((item) => (
          <li
            key={item.id}
            className='p-4 border-b rounded-xl hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer border-gray-600'
          >
            {item.id === 5 ? (
              <span onClick={logout}>{item.text}</span>
            ) : (
              <Link to={item.href}>{item.text}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Header;
