export default function Policy() {

    return (
        <>
            <div className='payment-body max-width-1200 p-24 mt-20 mb-20'>
                <div className='flex flex-col '>
                    <h2 className="fw-700">قوانین ثبت لایک</h2>
                    <ul class="post-ul list-style-inside">
                        <li>پیج شما بایستی عمومی (public) باشد.</li>
                        <li>تا اتمام سفارش پیج را خصوصی و دی اکتیو نکنید.</li>
                        <li>طبق سیاست های اینستاگرام لایک ها قائدتا دارای ریزش بوده و در هنگام ثبت سفارش به این نکته توجه کنید و آسمان مبر هیچ مسئولیتی در قبال ریزش ها ندارد</li>
                        <li>تا اتمام سفارش اسم پیج و کپشن پست را تغییر ندهید.</li>
                        <li>برای هر لینک پست اینستاگرام تا زمانی که سفارش آن تمام نشده، دوباره سفارش ندهید</li>
                        <li>با توجه به قیمت لایک ها ، لایک ها از اکانت های واقعی نیستند ، در صورت تمایل  به دریافت لایک واقعی با پشتیبانی تماس حاصل فرمایید</li>
                        <li>سفارشات در سریع ترین زمان ممکن انجام میشوند در صورتی که زمان انجام سفارشتان بیش از 24 ساعت به طول انجامید یا بصورت کامل انجام نشد با تیم پشتیبانی تماس حاصل فرمایید.</li>
                        <li>در صورت رعایت نکردن موارد فوق سایت آسمان ممبر هیچ مسئولیت و تعهدی بابت لغو سفارش شما ندارد.</li>
                        <li>سایت آسمان ممبر تابع قوانین جمهوری اسلامی ایران میباشد.</li>
                    </ul>
                </div>

            </div>

            <div className='payment-body max-width-1200 p-24 mt-20 mb-20'>
                <div className='flex flex-col '>
                    <h2 className="fw-700">قوانین ثبت ویو</h2>
                    <ul class="post-ul list-style-inside">
                        <li>پیج شما بایستی عمومی (public) باشد.</li>
                        <li>تا اتمام سفارش پیج را خصوصی و دی اکتیو نکنید.</li>
                        <li>برای هر لینک پست اینستاگرام تا زمانی که سفارش آن تمام نشده، دوباره سفارش ندهید</li>
                        <li>سفارشات در سریع ترین زمان ممکم انجام میشوند در صورتی که زمان انجام سفارشتان بیش از 24 ساعت به طول انجامید یا بصورت کامل انجام نشد با تیم پشتیبانی تماس حاصل فرمایید.</li>


                        <li>در صورت رعایت نکردن موارد فوق سایت آسمان ممبر هیچ مسئولیت و تعهدی بابت لغو سفارش شما ندارد.</li>


                        <li>سایت آسمان ممبر تابع قوانین جمهوری اسلامی ایران میباشد.</li>
                    </ul>
                </div>

            </div>

            <div className='payment-body max-width-1200 p-24 mt-20 mb-20'>
                <div className='flex flex-col '>
                    <h2 className="fw-700">قوانین ثبت فالوور</h2>
                    <ul class="post-ul list-style-inside">

                        <li>همانند تمامی سفارشات خرید فالوور در تمام دنیا ! ، هر فالوور خریدنی دارای ریزش میباشد و سرویس های سایت آسمان ممبر نیز از این قائده مستثنی نیستند، با توجه به کیفیت بالای پکیج های فالوور آسمان ممبر دارای کم ترین ریزش در بین سایت های ارائه فالوور میباشد، لکن ریزش کم به دلیل کیفیت بالا فالوورها  بوده و از این رو هیچگونه مسئولیتی بابت ریزش بر عهده ما نیست.</li>
                        <li>به دلیل قیمت مناسب پکیج ها و تحویل به موقع سفارشات، تاکنون، با افتخار ، میزبان بیش از 240000 سفارش بوده‌ایم.</li>
                        <li>با توجه به ریزش بالای فالوورهای ایرانی و استقبال کم مشتریان از فالوور ایرانی، سرویس مربوطه از سایت برداشته شد ، در صورت تمایل برای خرید فالوور ایرانی با پشتیبانی تماس بگیرید.</li>
                        <li>پیجی که برای درخواست فالوور ثبت کردید قبل از سفارش و موقع انجام سفارش بایستی عمومی (public) باشد.</li>
                        <li>تا اتمام سفارش پیج را خصوصی نکنید (حتی یک لحظه!).</li>
                        <li>تا اتمام سفارش اسم پیج را تغییر ندهید.</li>
                        <li>تا اتمام سفارش پیچ را دی اکتیو نکنید.</li>
                        <li>در صورت رعایت نکردن موارد فوق سایت آسمان ممبر هیچ مسئولیت و تعهدی بابت لغو سفارش شما ندارد.</li>
                        <li>
                            فالوورها تقریبا اصلا روی افزایش لایک و کامنت و ... پیجتان تاثیری ندارد، فقط تعداد فالوورهایتان افزایش می یابد، برای افزایش لایک میتوانید به پنل های <a href="https://asemanmember.ir/%d8%ae%d8%b1%db%8c%d8%af-%d9%84%d8%a7%db%8c%da%a9-%d8%a7%db%8c%d8%b1%d8%a7%d9%86%db%8c-%d9%88-%d8%ae%d8%a7%d8%b1%d8%ac%db%8c-%d8%a7%db%8c%d9%86%d8%b3%d8%aa%d8%a7%da%af%d8%b1%d8%a7%d9%85/" target="_blank" rel="noopener">خرید لایک</a> آسمان ممبر مراجعه کنید.
                        </li>

                        <li>سایت آسمان ممبر تابع قوانین جمهوری اسلامی ایران میباشد.</li>
                    </ul>
                </div>

            </div>
        </>
    )
}
