import React from 'react'
import './loading.css'
const Loading = () => {
    return (
        <div className='flex flex-col items-center'>
            <span className="loader"></span>
            <span className='mt-10'>لطفا صبر کنید</span>
        </div>
    )
}

export default Loading