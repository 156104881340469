import React, { useEffect, useState } from 'react';
import axios from 'axios';
import payFailed from '../../assets/images/pay-failed.png';
import paySuccess from '../../assets/images/pay-success.png';
import Loading from '../../utils/Loading';

const GetPayment = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {


    const fetchData = async () => {
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams(window.location.search);
        const status = queryParams.get('Status');
        const authority = queryParams.get('Authority');

        const response = await axios.get(
          `${process.env.REACT_APP_URL}/order/verify-payment?Status=${status}&Authority=${authority}`
        );

        setPaymentData(response.data);
      } catch (error) {
        console.log(error);
        setError(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

  }, []);

  return (
    <>

      <div className='payment-body p-24 mt-20 mb-20'>
        {isLoading && <Loading />}
        {error ? (
          <div className='flex flex-col items-center'>
            <img src={payFailed} alt="Pay Images" className='' />
            <div className='text-red fw-700'>{error}</div>
            <div className='mt-20'>در صورت وجود مشکل در انجام پرداخت و خرید سرویس، با پشتیبانی تماس حاصل فرمایید</div>
          </div>
        ) : (
          paymentData && (
            <div>
              <div className='flex flex-col items-center mb-14'>
                <img src={paySuccess} alt="Pay Images" className='' />
                <div className='text-green fs-25 fw-700 mb-14'>{paymentData.message}</div>
                <div className='fw-700'>{paymentData.payment?.priceOrder.toLocaleString()} تومان</div>
              </div>

              <hr className='mb-14' />

              <div className='flex justify-between mb-14'>
                <div className='fw-700'>کد پیگیری</div>
                <div>{paymentData.payment?.anotherPanelId}</div>
              </div>

              <div className='flex justify-between mb-14'>
                <div className='fw-700'>شناسه پرداخت</div>
                <div>{paymentData.payment?.RefID}</div>
              </div>

              <div className='flex justify-between mb-14'>
                <div className='fw-700'>تعداد</div>
                <div>{paymentData.payment?.quantity}</div>
              </div>

              <div className='flex justify-between mb-14'>
                <div className='fw-700'>تاریخ</div>
                <div>{paymentData.payment?.createdAt}</div>
              </div>

              <div className='flex justify-between mb-14'>
                <div className='fw-700'>شماره تماس</div>
                <div>{paymentData.payment?.mobileNumber}</div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default GetPayment;
